import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.clockmaker-game.com/',
        prepareHeaders: (headers) => {
            return headers
        }
    }),
    endpoints: () => ({})
})

export default api
