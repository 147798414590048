import {getUserLanguage} from "../../utils";

const types = {
    APPROVE_COOKIE: 'APPROVE_COOKIE',
    APPROVE_NOTIFICATIONS: 'APPROVE_NOTIFICATIONS',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
};

const initialState = {
    language: getUserLanguage(),
    cookie: null,
    notifications: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.APPROVE_COOKIE:
            return {
                ...state,
                cookie: action.payload,
            };
        case types.APPROVE_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload,
            };
        case types.CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };

        default:
            return state;
    }
};
