import React, { createContext, useContext, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import translations from "../utils/translations";

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
    const dispatch = useDispatch()
    const userState = useSelector((state) => state.data.user)
    const [language, setLanguage] = useState(userState.language);

    const changeLanguage = (value) => {
        dispatch({ type: 'CHANGE_LANGUAGE', payload: value })
        console.log('changeLanguage')
        console.log(value)
        window.localStorage.setItem('language', value)
        window.language = value
        setLanguage(value)
    };

    const getT = (text) => {
        if(!translations.hasOwnProperty(language.toLowerCase())) return translations['en'][text]
        return translations[language.toLowerCase()].hasOwnProperty(text) ? translations[language.toLowerCase()][text] : translations['en'][text]
    }

    return (
        <LanguageContext.Provider value={{ language, changeLanguage, getT }}>
            {children}
        </LanguageContext.Provider>
    );
};