export const getUserLanguage = () => {
    const languages = [
        { code: 'EN', label: 'English', flagClass: 'fi-en' },
        { code: 'ES', label: 'Español', flagClass: 'fi-es' },
        { code: 'IT', label: 'Italiano', flagClass: 'fi-it' },
        { code: 'PT_BR', label: 'Português do Brasil', flagClass: 'fi-pt_br' },
        { code: 'NL', label: 'Nederlands', flagClass: 'fi-nl' },
        { code: 'DE', label: 'Deutsch', flagClass: 'fi-de' },
        { code: 'FR', label: 'Français', flagClass: 'fi-fr' },
        { code: 'PT', label: 'Português de Portugal', flagClass: 'fi-pt' },
        { code: 'RU', label: 'Русский', flagClass: 'fi-ru' },
        { code: 'KO', label: '한국어', flagClass: 'fi-ko' },
        { code: 'JA', label: '日本語', flagClass: 'fi-ja' },
        { code: 'ZH', label: '简体中文', flagClass: 'fi-zh' },
    ];

    // Fetch stored language or browser language as fallback
    let userLang = localStorage.getItem('language') || navigator.language || navigator.userLanguage;

    // Normalize userLang to match the format of language codes in your list
    userLang = userLang.toUpperCase().replace('-', '_');

    // Check if the retrieved language exists in the supported languages list
    const matchedLanguage = languages.find(lang => userLang.includes(lang.code));

    // Return the matched language code or default to 'EN'
    return matchedLanguage ? matchedLanguage.code : 'EN';
}