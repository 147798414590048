import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {ListItemButton} from '@mui/material';

import {useSelector} from "react-redux";
import {useLanguage} from "../context/LanguageContext";

export default function LanguagePopup({handleLanguageChange, hidepopup}) {
    const [open, setOpen] = useState(true); // Start with the popup open
    const userState = useSelector((state) => state.data.user)
    const handleClose = () => {
        setOpen(false);
        hidepopup(false)

    };
    const { language, changeLanguage, getT } = useLanguage();

    const languages = [
        { code: 'EN', label: 'English', flagClass: 'fi-en' },
        { code: 'ES', label: 'Español', flagClass: 'fi-es' },
        { code: 'IT', label: 'Italiano', flagClass: 'fi-it' },
        { code: 'PT_BR', label: 'Português do Brasil', flagClass: 'fi-pt_br' },
        { code: 'NL', label: 'Nederlands', flagClass: 'fi-nl' },
        { code: 'DE', label: 'Deutsch', flagClass: 'fi-de' },
        { code: 'FR', label: 'Français', flagClass: 'fi-fr' },
        { code: 'PT', label: 'Português de Portugal', flagClass: 'fi-pt' },
        { code: 'ru', label: 'Русский', flagClass: 'fi-ru' },
        { code: 'KO', label: '한국어', flagClass: 'fi-ko' },
        { code: 'JA', label: '日本語', flagClass: 'fi-ja' },
        { code: 'ZH', label: '简体中文', flagClass: 'fi-zh' },
    ];

    return (
        <Dialog maxWidth={"xs"} fullWidth={true} onClose={handleClose} open={open}>
            <DialogTitle>
                {getT("language")}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <List sx={{ pt: 0 }}>
                {languages.map((language) => (
                    <ListItemButton selected={language.code.toUpperCase() == userState.language.toUpperCase()}  key={language.code} onClick={() => {
                        handleLanguageChange(language.code)
                        hidepopup(false)

                    }}>
                        <span className={`fi ${language.flagClass}`} style={{marginRight: '10px'}}></span>
                        <ListItemText primary={language.label} />

                    </ListItemButton>
                ))}
            </List>
        </Dialog>
    );
}