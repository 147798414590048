const translations = {
    "en": {
        "login": "Connect",
        "register": "Register",
        "support": "Support",
        "privacy": "Privacy Policy",
        "terms": "Terms of Service",
        "show_notification_title": "Turn On Notifications",
        "show_notification_description": "To receive notifications from the game, turn them on in the settings. If you don't know how, please view the <a href='https://clockmaker.zendesk.com/hc/en-us/articles/24660426522001-Enabling-Browser-Notifications' target='_blank'>instructions.</a>",
        "show_notification_active": "To receive notifications from the game, turn them on in the settings. If you don't know how, please view the instructions.",
        "show_notification_denied": "To receive notifications from the game, turn them on in the settings. If you don't know how, please view the instructions.",
        "install": "Install",
        "language": "Language",
        "yes": "Yes",
        "no": "No"
    },
    "es": {
        "login": "Conectar",
        "register": "Registrarse",
        "support": "Soporte",
        "privacy": "Política de privacidad",
        "terms": "Términos y condiciones de uso",
        "show_notification_title": "Activa las notificaciones",
        "show_notification_description": "Para recibir notificaciones del juego, actívalas en los ajustes. Si no sabes hacerlo, lee las <a href='https://clockmaker.zendesk.com/hc/es/articles/24660426522001-Enabling-Browser-Notifications' target='_blank'>instrucciones</a>.",
        "install": "Instalar",
        "language": "Idioma",
        "yes": "Sí",
        "no": "No"
    },
    "it": {
        "login": "Connetti",
        "register": "Registrati",
        "support": "Assistenza",
        "privacy": "Informativa sulla privacy",
        "terms": "Condizioni d'uso",
        "show_notification_title": "Attiva le notifiche",
        "show_notification_description": "Per ricevere le notifiche dal gioco, attivale nelle impostazioni. Se non sai come fare, consulta le <a href='https://clockmaker.zendesk.com/hc/it/articles/24660426522001-Enabling-Browser-Notifications' target='_blank'>istruzioni</a>.",
        "install": "Installa",
        "language": "Lingua",
        "yes": "Sì",
        "no": "No"
    },
    "pt_br": {
        "login": "Conectar",
        "register": "Registrar",
        "support": "Suporte",
        "privacy": "Política de Privacidade",
        "terms": "Termos de Serviço",
        "show_notification_title": "Ativar notificações",
        "show_notification_description": "Para receber notificações do jogo, ative-as nas configurações. Se não souber como, consulte as <a href='https://clockmaker.zendesk.com/hc/pt-br/articles/24660426522001-Enabling-Browser-Notifications' target='_blank'>instruções</a>.",
        "install": "Instalar",
        "language": "Idioma",
        "yes": "Sim",
        "no": "Não"
    },
    "nl": {
        "login": "Verbinden",
        "register": "Registreren",
        "support": "Ondersteuning",
        "privacy": "Privacybeleid",
        "terms": "Servicevoorwaarden",
        "show_notification_title": "Schakel meldingen in",
        "show_notification_description": "Om meldingen van het spel te ontvangen, moet je ze inschakelen in de instellingen. Als je niet weet hoe dit moet, kun je de <a href='https://clockmaker.zendesk.com/hc/en-us/articles/24660426522001-Enabling-Browser-Notifications' target='_blank'>instructies</a> doornemen.",
        "install": "Installeren",
        "language": "Taal",
        "yes": "Ja",
        "no": "Nee"
    },
    "de": {
        "login": "Verbinden",
        "register": "Registrieren",
        "support": "Support",
        "privacy": "Datenschutzerklärung",
        "terms": "Nutzungsbedingungen",
        "show_notification_title": "Aktiviere Benachrichtigungen",
        "show_notification_description": "Um Benachrichtigungen vom Spiel zu erhalten, aktiviere sie in den Einstellungen. Wenn du nicht weißt, wie das geht, sieh dir bitte die <a href='https://clockmaker.zendesk.com/hc/de/articles/24660426522001-Aktiviere-Browser-Benachrichtigungen' target='_blank'>Anweisungen an</a>.",
        "install": "Installieren",
        "language": "Sprache",
        "yes": "Ja",
        "no": "Nein"
    },
    "fr": {
        "login": "Connexion",
        "register": "S'enregistrer",
        "support": "Assistance",
        "privacy": "Charte de confidentialité",
        "terms": "Conditions d'utilisation",
        "show_notification_title": "Active les notifications",
        "show_notification_description": "Pour recevoir des notifications du jeu, active-les dans les paramètres. Si tu ne sais pas comment faire, nous t'invitons à consulter les <a href='https://clockmaker.zendesk.com/hc/fr/articles/24660426522001-Enabling-Browser-Notifications' target='_blank'>instructions</a>.",
        "install": "Installer",
        "language": "Langue",
        "yes": "Oui",
        "no": "Non"
    },
    "pt": {
        "login": "Ligar",
        "register": "Registar",
        "support": "Assistência",
        "privacy": "Política de Privacidade",
        "terms": "Termos de Serviço",
        "show_notification_title": "Ativar notificações",
        "show_notification_description": "Para receberes notificações do jogo, ativa-as nas definições. Se não souberes como, consulta as <a href='https://clockmaker.zendesk.com/hc/pt-br/articles/24660426522001-Enabling-Browser-Notifications' target='_blank'>instruções</a>.",
        "install": "Instalar",
        "language": "Idioma",
        "yes": "Sim",
        "no": "Não"
    },
    "ru": {
        "login": "Войти",
        "register": "Зарегистрироваться",
        "support": "Поддержка",
        "privacy": "Политика конфиденциальности",
        "terms": "Условия пользования",
        "show_notification_title": "Включите уведомления",
        "show_notification_description": "Чтобы получать уведомления от игры, включите их в настройках. Если вы не знаете, как это сделать, посмотрите <a href='https://clockmaker.zendesk.com/hc/ru/articles/24660426522001-Enabling-Browser-Notifications' target='_blank'>инструкцию</a>.",
        "show_notification_active": "Уведомления включены.",
        "show_notification_denied": "Чтобы получать уведомления от игры, включите их в настройках. Если вы не знаете, как это сделать, посмотрите инструкцию.",
        "install": "Установить",
        "language": "Язык",
        "yes": "Да",
        "no": "Нет"
    },
    "ko": {
        "login": "연결",
        "register": "등록",
        "support": "고객지원",
        "privacy": "개인정보 보호정책",
        "terms": "서비스 약관",
        "show_notification_title": "알림을 켜세요",
        "show_notification_description": "게임에서 알림을 받으려면 설정에서 알림을 켜세요. <a href='https://clockmaker.zendesk.com/hc/ko/articles/24660426522001-Enabling-Browser-Notifications' target='_blank'>방법을 모르면 설명을 확인해 주시기 바랍니다</a>.",
        "install": "설치",
        "language": "언어",
        "yes": "예",
        "no": "아니오"
    },
    "ja": {
        "login": "接続する",
        "register": "登録する",
        "support": "サポート",
        "privacy": "プライバシーポリシー",
        "terms": "利用規約",
        "show_notification_title": "通知をオンにしましょう",
        "show_notification_description": "ゲームからの通知を受け取るには、設定から通知をオンにする必要があります。ご存知ない場合は<a href='https://clockmaker.zendesk.com/hc/jp/articles/24660426522001-Enabling-Browser-Notifications' target='_blank'>手順をご覧</a>ください。",
        "install": "インストールする",
        "language": "言語",
        "yes": "はい",
        "no": "いいえ"
    },
    "zh": {
        "login": "连接",
        "register": "注册",
        "support": "支持",
        "privacy": "隐私政策",
        "terms": "服务条款",
        "show_notification_title": "打开通知",
        "show_notification_description": "你可以在设置中开启并接收游戏通知。<a href='https://clockmaker.zendesk.com/hc/zh-cn/articles/24660426522001-Enabling-Browser-Notifications' target='_blank'>若不了解操作方法，请查阅相关指南。</a>",
        "install": "安装",
        "language": "语言",
        "yes": "是",
        "no": "否"
    }
};

const translations2 = {
    "login": {
        "en": "Connect",
        "es": "Conectar",
        "it": "Connetti",
        "pt_br": "Conectar",
        "nl": "Verbinden",
        "de": "Verbinden",
        "fr": "Connexion",
        "pt": "Ligar",
        "ru": "Войти",
        "ko": "연결",
        "ja": "接続する",
        "zh": "连接"
    },
    "register": {
        "en": "Register",
        "es": "Registrarse",
        "it": "Registrati",
        "pt_br": "Registrar",
        "nl": "Registreren",
        "de": "Registrieren",
        "fr": "S'enregistrer",
        "pt": "Registar",
        "ru": "Зарегистрироваться",
        "ko": "등록",
        "ja": "登録する",
        "zh": "注册"
    },
    "support": {
        "en": "Support",
        "es": "Soporte",
        "it": "Assistenza",
        "pt_br": "Suporte",
        "nl": "Ondersteuning",
        "de": "Support",
        "fr": "Assistance",
        "pt": "Assistência",
        "ru": "Поддержка",
        "ko": "고객지원",
        "ja": "サポート",
        "zh": "支持"
    },
    "privacy": {
        "en": "Privacy Policy",
        "es": "Política de privacidad",
        "it": "Informativa sulla privacy",
        "pt_br": "Política de Privacidade",
        "nl": "Privacybeleid",
        "de": "Datenschutzerklärung",
        "fr": "Charte de confidentialité",
        "pt": "Política de Privacidade",
        "ru": "Политика конфиденциальности",
        "ko": "개인정보 보호정책",
        "ja": "プライバシーポリシー",
        "zh": "隐私政策"
    },
    "terms": {
        "en": "Terms of Service",
        "es": "Términos y condiciones de uso",
        "it": "Condizioni d'uso",
        "pt_br": "Termos de Serviço",
        "nl": "Servicevoorwaarden",
        "de": "Nutzungsbedingungen",
        "fr": "Conditions d'utilisation",
        "pt": "Termos de Serviço",
        "ru": "Условия пользования",
        "ko": "서비스 약관",
        "ja": "利用規約",
        "zh": "服务条款"
    },
    "show_notification_title": {
        "en": "Turn On Notifications",
        "es": "Activa las notificaciones",
        "it": "Attiva le notifiche",
        "pt_br": "Ativar notificações",
        "nl": "Schakel meldingen in",
        "de": "Aktiviere Benachrichtigungen",
        "fr": "Active les notifications",
        "pt": "Ativar notificações",
        "ru": "Включите уведомления",
        "ko": "알림을 켜세요",
        "ja": "通知をオンにしましょう",
        "zh": "打开通知"
    },
    "show_notification_description": {
        "en": "To receive notifications from the game, turn them on in the settings. If you don't know how, please view the instructions.",
        "es": "Para recibir notificaciones del juego, actívalas en los ajustes. Si no sabes hacerlo, lee las instrucciones.",
        "it": "Per ricevere le notifiche dal gioco, attivale nelle impostazioni. Se non sai come fare, consulta le istruzioni.",
        "pt_br": "Para receber notificações do jogo, ative-as nas configurações. Se não souber como, consulte as instruções.",
        "nl": "Om meldingen van het spel te ontvangen, moet je ze inschakelen in de instellingen. Als je niet weet hoe dit moet, kun je de instructies doornemen.",
        "de": "Um Benachrichtigungen vom Spiel zu erhalten, aktiviere sie in den Einstellungen. Wenn du nicht weißt, wie das geht, sieh dir bitte die Anweisungen an.",
        "fr": "Pour recevoir des notifications du jeu, active-les dans les paramètres. Si tu ne sais pas comment faire, nous t'invitons à consulter les instructions.",
        "pt": "Para receberes notificações do jogo, ativa-as nas definições. Se não souberes como, consulta as instruções.",
        "ru": "Чтобы получать уведомления от игры, включите их в настройках. Если вы не знаете, как это сделать, посмотрите инструкцию.",
        "ko": "게임에서 알림을 받으려면 설정에서 알림을 켜세요. 방법을 모르면 설명을 확인해 주시기 바랍니다.",
        "ja": "ゲームからの通知を受け取るには、設定から通知をオンにする必要があります。ご存知ない場合は手順をご覧ください。",
        "zh": "你可以在设置中开启并接收游戏通知。若不了解操作方法，请查阅相关指南。"
    },
    "show_notification_active": {
        "en": "To receive notifications from the game, turn them on in the settings. If you don't know how, please view the instructions.",
        "ru": "Уведомления включены."
    },
    "show_notification_denied": {
        "en": "To receive notifications from the game, turn them on in the settings. If you don't know how, please view the instructions.",
        "ru": "Чтобы получать уведомления от игры, включите их в настройках. Если вы не знаете, как это сделать, посмотрите инструкцию."
    },
    "install": {
        "en": "Install",
        "es": "Instalar",
        "it": "Installa",
        "pt_br": "Instalar",
        "nl": "Installeren",
        "de": "Installieren",
        "fr": "Installer",
        "pt": "Instalar",
        "ru": "Установить",
        "ko": "설치",
        "ja": "インストールする",
        "zh": "安装"
    },
    "language": {
        "en": "Language",
        "es": "Idioma",
        "it": "Lingua",
        "pt_br": "Idioma",
        "nl": "Taal",
        "de": "Sprache",
        "fr": "Langue",
        "pt": "Idioma",
        "ru": "Язык",
        "ko": "언어",
        "ja": "言語",
        "zh": "语言"
    },
    "yes": {
        "en": "Yes",
        "es": "Sí",
        "it": "Sì",
        "pt_br": "Sim",
        "nl": "Ja",
        "de": "Ja",
        "fr": "Oui",
        "pt": "Sim",
        "ru": "Да",
        "ko": "예",
        "ja": "はい",
        "zh": "是"
    },
    "no": {
        "en": "No",
        "es": "No",
        "it": "No",
        "pt_br": "Não",
        "nl": "Nee",
        "de": "Nein",
        "fr": "Non",
        "pt": "Não",
        "ru": "Нет",
        "ko": "아니오",
        "ja": "いいえ",
        "zh": "否"
    },
    "loading": {
        en: "Loading",
        es: "Cargando",
        it: "Caricamento",
        pt_br: "Carregando",
        nl: "Laden",
        de: "Laden",
        fr: "Chargement",
        pt: "A carregar",
        ru: "Загрузка",
        ko: "로딩 중",
        ja: "ロード中",
        zh: "加载中"
    },
    "game_start_error": {
        "en": "Unable to start the game on a mobile device. Please select a suitable platform to install.",
        "es": "No se puede iniciar el juego en un dispositivo móvil. Por favor, selecciona una plataforma adecuada para instalar.",
        "it": "Impossibile avviare il gioco su un dispositivo mobile. Seleziona una piattaforma adatta per l'installazione.",
        "pt_br": "Não é possível iniciar o jogo em um dispositivo móvel. Por favor, selecione uma plataforma adequada para instalar.",
        "nl": "Het spel kan niet worden gestart op een mobiel apparaat. Selecteer een geschikt platform om te installeren.",
        "de": "Das Spiel kann auf einem mobilen Gerät nicht gestartet werden. Bitte wählen Sie eine geeignete Plattform zur Installation aus.",
        "fr": "Impossible de démarrer le jeu sur un appareil mobile. Veuillez sélectionner une plateforme appropriée pour l'installation.",
        "pt": "Não é possível iniciar o jogo num dispositivo móvel. Por favor, selecione uma plataforma adequada para instalar.",
        "ru": "Невозможно запустить игру на мобильном устройстве. Пожалуйста, выберите подходящую платформу для установки.",
        "ko": "모바일 기기에서 게임을 시작할 수 없습니다. 설치할 적합한 플랫폼을 선택해 주세요.",
        "ja": "モバイルデバイスでゲームを開始できません。適切なプラットフォームを選択してください。",
        "zh": "无法在移动设备上启动游戏。请选择适合安装的平台。"
    },
    'no_close':  {
        en: "No, thank you",
        es: "No, gracias",
        it: "No, grazie",
        pt_br: "Não, obrigado",
        nl: "Nee, bedankt",
        de: "Nein, danke",
        fr: "Non, merci",
        pt: "Não, obrigado",
        ru: "Нет, спасибо",
        ko: "괜찮아요",
        ja: "表示しない",
        zh: "不必了"
    },
    'show_instructions':  {
        en: "See instructions",
        es: "Ver instrucciones",
        it: "Vedi le istruzioni",
        pt_br: "Ver instruções",
        nl: "Instructies bekijken",
        de: "Anweisungen ansehen",
        fr: "Voir les instructions",
        pt: "Ver instruções",
        ru: "Смотреть инструкцию",
        ko: "지침 보기",
        ja: "説明を表示する",
        zh: "查看说明"
    },
    'show_instructions_link': {
        "en": "https://clockmaker.zendesk.com/hc/en-us/articles/24660426522001-Enabling-Browser-Notifications",
        "es": "https://clockmaker.zendesk.com/hc/es/articles/24660426522001-Enabling-Browser-Notifications",
        "it": "https://clockmaker.zendesk.com/hc/it/articles/24660426522001-Enabling-Browser-Notifications",
        "pt_br": "https://clockmaker.zendesk.com/hc/pt-br/articles/24660426522001-Enabling-Browser-Notifications",
        "nl": "https://clockmaker.zendesk.com/hc/en-us/articles/24660426522001-Enabling-Browser-Notifications",
        "de": "https://clockmaker.zendesk.com/hc/de/articles/24660426522001-Enabling-Browser-Notifications",
        "fr": "https://clockmaker.zendesk.com/hc/fr/articles/24660426522001-Enabling-Browser-Notifications",
        "pt": "https://clockmaker.zendesk.com/hc/pt-br/articles/24660426522001-Enabling-Browser-Notifications",
        "ru": "https://clockmaker.zendesk.com/hc/ru/articles/24660426522001-Enabling-Browser-Notifications",
        "ko": "https://clockmaker.zendesk.com/hc/ko/articles/24660426522001-Enabling-Browser-Notifications",
        "ja": "https://clockmaker.zendesk.com/hc/ja/articles/24660426522001-Enabling-Browser-Notifications",
        "zh": "https://clockmaker.zendesk.com/hc/zh-cn/articles/24660426522001-Enabling-Browser-Notifications"
    },

    "install_descr": {
        "en": "Click the install button in the address bar, or refresh the page and try again.",
        "es": "Haz clic en el botón de instalación en la barra de direcciones, o actualiza la página e inténtalo de nuevo.",
        "it": "Clicca sul pulsante di installazione nella barra degli indirizzi, oppure aggiorna la pagina e riprova.",
        "pt_br": "Clique no botão de instalação na barra de endereços, ou atualize a página e tente novamente.",
        "nl": "Klik op de installatieknop in de adresbalk, of ververs de pagina en probeer opnieuw.",
        "de": "Klicken Sie auf die Installations-Schaltfläche in der Adressleiste, oder aktualisieren Sie die Seite und versuchen Sie es erneut.",
        "fr": "Cliquez sur le bouton d'installation dans la barre d'adresse, ou rafraîchissez la page et réessayez.",
        "pt": "Clique no botão de instalação na barra de endereços, ou atualize a página e tente novamente.",
        "ru": "Нажмите на кнопку установки в адресной строке либо обновите страницу и попробуйте еще раз",
        "ko": "주소 표시줄의 설치 버튼을 클릭하거나 페이지를 새로고침하고 다시 시도하세요.",
        "ja": "アドレスバーのインストールボタンをクリックするか、ページを更新してもう一度試してください。",
        "zh": "点击地址栏中的安装按钮，或刷新页面后重试。"
    }
}

function transformToOldFormat(newTranslations) {
    const oldFormat = {};
    Object.keys(newTranslations).forEach(key => {
        Object.keys(newTranslations[key]).forEach(lang => {
            if (!oldFormat[lang]) {
                oldFormat[lang] = {};
            }
            oldFormat[lang][key] = newTranslations[key][lang];
        });
    });
    return oldFormat;
}

export default transformToOldFormat(translations2);