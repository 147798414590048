import api from "./api"

export const requestsApi = api.injectEndpoints({
    endpoints: (build) => ({
        saveToken: build.mutation({
            query: (body = {}) => ({
                url: `token`,
                method: "POST",
                body: body
            }),
        }),
        checkToken: build.mutation({
            query: (body = {}) => ({
                url: `check_token`,
                method: "POST",
                body: body
            }),
        }),
        cancelNotification: build.mutation({
            query: (body = {}) => ({
                url: `cancel_notifications`,
                method: "POST",
                body: body
            }),
        }),
        sendMsg: build.mutation({
            query: (body = {}) => ({
                url: `send_message`,
                method: "POST",
                body: body
            }),
        }),
    }),
})

export const {
    useSaveTokenMutation,
    useCancelNotificationMutation,
    useCheckTokenMutation,
    useSendMsgMutation,
} = requestsApi